export default {
  badge: '徽章',
  open: 'Open',
  close: '關閉',
  confirmEdit: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  dataIterator: {
    noResultsText: '沒有符合條件的結果',
    loadingText: '讀取中...',
  },
  dataTable: {
    itemsPerPageText: '每頁列數：',
    ariaLabel: {
      sortDescending: '：降序排列。',
      sortAscending: '：升序排列。',
      sortNone: '無排序方式。點擊以升序排列。',
      activateNone: '點擊以移除排序方式。',
      activateDescending: '點擊以降序排列。',
      activateAscending: '點擊以移除排序方式。',
    },
    sortBy: '排序方式',
  },
  dataFooter: {
    itemsPerPageText: '每頁項目：',
    itemsPerPageAll: '全部',
    nextPage: '下一頁',
    prevPage: '上一頁',
    firstPage: '第一頁',
    lastPage: '最後頁',
    pageText: '{2} 條中的 {0}~{1} 條',
  },
  dateRangeInput: {
    divider: 'to',
  },
  datePicker: {
    itemsSelected: '{0} selected',
    range: {
      title: 'Select dates',
      header: 'Enter dates',
    },
    title: 'Select date',
    header: 'Enter date',
    input: {
      placeholder: 'Enter date',
    },
  },
  noDataText: '沒有資料',
  carousel: {
    prev: '上一張',
    next: '下一張',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '還有其他 {0} 項',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
    otp: 'Please enter OTP character {0}',
  },
  fileInput: {
    counter: '{0} 個檔案',
    counterSize: '{0} 個檔案（共 {1}）',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: '分頁導航',
      next: '下一頁',
      previous: '上一頁',
      page: '轉到頁面 {0}',
      currentPage: '當前頁 {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  stepper: {
    next: 'Next',
    prev: 'Previous',
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
